import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import store from 'core/store'

const router = new VueRouter({
    routes: [
        {
            path: '',
            component: () => import('layouts/default'),
            children: [
                {
                    path: '/',
                    name: 'main',
                    component: () => import('pages/main'),
                },
            ]
        },
        {
            path: '',
            component: () => import('layouts/default'),
            meta: { auth: true },
            children: [
                {
                    path: '/settings',
                    name: 'settings',
                    component: () => import('pages/settings'),
                },
                {
                    path: '/admin/',
                    name: 'admin',
                    meta: { admin: true },
                    component: () => import('pages/admin'),
                },
                {
                    path: '*',
                    name: '404',
                    component: () => import('pages/404')
                },
            ]
        },
        {
            path: '/auth/',
            name: 'auth',
            component: () => import('layouts/auth'),
            children: [
                {
                    path: 'login',
                    alias: '',
                    name: 'login',
                    component: () => import('pages/auth/login'),
                },
                {
                    path: 'signup',
                    name: 'signup',
                    component: () => import('pages/auth/signup'),
                },
            ]
        },
	],

	mode: 'history',
	scrollBehavior(to, from, savedPosition) {
		if (to.name == from.name) return
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	}
})

router.beforeEach(async (to, from, next) => {
    await store.getters['user/loaded']

    const auth = store.state.user.auth,
        required = to.matched.find(route => route.meta.auth) ? true : false
    console.log(to, required)

    if (auth) next()
    else
        if (required) {
            store.state.ui.redirect = to
            await next({ name: 'main' })
            store.commit('ui/popup', { name: 'auth', query: 'login' })
        }
        else next()
})
// router.afterEach(async (to, from) => {
// })

export default router
