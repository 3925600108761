<template lang="pug">

.layout-auth
    router-view.g-panel.auth

</template>
<script>
export default {
    
}
</script>
<style lang="stylus" scoped>

.layout-auth
    display flex
    justify-content center
    align-items center

.auth
    padding 20px
    width 260px
    >>>
        .title
            font-size 20px
            line-height 1.2
            font-weight 600
            margin-bottom 15px
        .g-field
            margin-top 10px
            &:first-child
                margin-top 0
        button
            width 100%
            margin-top 15px
        .switch
            font-size 14px
            margin-top 15px
            line-height 1.1

</style>
