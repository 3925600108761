import axios from 'axios'
import router from 'core/router'
import Cookies from 'js-cookie'

export default {
    namespaced: true,
    state: () => ({
        info: null
    }),
    getters: {
    },
    mutations: {
    },
    actions: {
        async init({ state }) {
            const { data } = await axios.get('/api/service/site_info/')
            state.info = data.info
        },
        async buy({ commit, dispatch, state, rootState }) {
            const { data } = await axios.post('/api/service/buy/')
            console.log(data)
            
            if (data.status == true)
                rootState.user.user.paid = true
        },
    }
}
