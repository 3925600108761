<template lang="pug">

.header
    router-link.logo(:to="{ name: 'main' }")
        img(:src="require('img/logo.svg')")
    //- router-link.logo(:to="{ name: 'main' }") Вы орёте великолепно
    //- .nav
    //-     router-link.link(:to="{ name: 'main' }") Главная
    //-     router-link.link(v-if="user" :to="{ name: 'settings' }") Настройки
    //-     router-link.link(v-if="admin" :to="{ name: 'admin' }") Администрирование
    .options
        router-link.g-btn-primary.link(v-if="admin" :to="{ name: 'admin' }") Администрирование
        router-link.g-btn-primary.link(v-if="user" :to="{ name: 'settings' }") Настройки
        .g-btn-primary.login(v-else @click="$store.commit('ui/popup', { name: 'auth' })") Войти

</template>
<script>
export default {
    data: () => ({
    }),
    computed: {
        user() {
            return this.$store.state.user.user
        },
        admin() {
            return this.user ? this.user.admin : false
        }
    }
}
</script>
<style lang="stylus" scoped>

.header
    display flex
    justify-content space-between
    align-items center
    margin-top 50px

.logo
    // height 162px
    img
        height 120px

.options
    .link
        margin-right 26px
        &:last-child
            margin-right 0

</style>
