import axios from 'axios'
import router from 'core/router'
import Cookies from 'js-cookie'

export default {
    namespaced: true,
    state: () => ({
        user: null,
        auth: false,
        loaded: false,
    }),
    getters: {
        loaded(state) {
            return new Promise((resolve, reject) => {
                const interval = setInterval(() => {
                    if (state.loaded) {
                        resolve(true)
                        clearInterval(interval)
                    }
                }, 50)
            })
        },
    },
    mutations: {
        setUser(state, user) {
            state.auth = true
            state.user = user
        },
        clearUser(state) {
            state.user = null
        },
        setToken(state, tokens) {
            axios.defaults.headers.common['X-CSRFToken'] = Cookies.get('csrftoken')
        }
    },
    actions: {
        async init({ commit, dispatch, state }) {
            const { data } = await axios.get('/api/auth/user/')
            console.log(data)
            
            if (data.user)
                commit('setUser', data.user)
            
            commit('setToken')
            state.loaded = true
        },

        async login({ state, commit, dispatch }, { phone, password }) {
            try {
                const { data } = await axios.post('/api/auth/login/', { phone, password })

                if (data.error)
                    return data

                commit('setUser', data.user)
                commit('setToken')
                commit('ui/redirect', { name: 'main' }, { root: true })

                return true
            } catch (error) {
                console.log(error)
                return false
            }
        },

        async signup({ state, commit, dispatch }, { phone, password, name }) {
            try {
                const { data } = await axios.post('/api/auth/signup/', { phone, password, name })

                if (data.error)
                    return data

                commit('setUser', data.user)
                commit('setToken')
                commit('ui/redirect', { name: 'main' }, { root: true })

                return true
            } catch (error) {
                console.log(error)
                return false
            }
        },

        async logout({ state, commit }) {
            const { data } = await axios.post('/api/auth/logout/')

            commit('setToken')
            state.auth = false
            
            commit('clearUser')
            await router.push({ name: 'main' })
            return true
        },

        async changeAuth({ state, commit }, { phone, password }) {
            const { data } = await axios.post('/api/auth/change_auth/', {
                phone,
                password
            })
            
            commit('setUser', data.user)
            commit('setToken')

            return data
        },

    }
}
